@import '../modules/login';

.supervisor-login {
  background: #ffffff;
  padding: 2em 3em 1em;
  width: 35em;

  img {
    margin-bottom: 2em;
  }

  &.mobile {
    padding: 1em;
    width: 100%;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .heading {
    color: $theme;
    font-size: 1.25em;
    font-weight: bold;
    margin-bottom: 1em;

  }

  .subtext {
    color: $grey-body;
    font-size: 0.9em;
    line-height: 1.1em;
    margin-bottom: 4em;
  }

  .login-button {
    background: $theme;
    border: 0;
    color: #ffffff;
    height: 4.2rem;
  }

  .logout-button {
    background: $theme-trainee-light;
    border: 0;
    color: #ffffff;
    height: 4.2rem;
  }

  .cancel {
    background: $grey;
    border: 0;
    color: $grey-body;
    font-size: 0.8em;
    height: 4.2rem;
  }
}

.login-container {
  background: $white;
  color: $grey-body;
  padding: 2em 4em;
  margin: auto;
}

.login-container__heading {
  color: $theme;
  font-size: 1.25em;
  font-weight: bold;
}

.login-container__subheading {
  font-size: .75em;
  line-height: 1.2em;
  margin-top: .5em;
}

.login-container__label {
  margin-top: 1em;
  color: $theme;
}

.login-container__submit {
  background: $theme;
  border: 0;
  color: $white;
  margin-top: 1em;

  &:hover {
    background: $theme;
    border: 0;
    color: $white;
  }
}

.login-container__spinner {
  text-align: center;
}


.login-container__notify {
  color: white;
  margin-top: 3em;
  padding: .5em;
}

.login-container__notify--error {
  background: $danger;
}

.login-container__notify--warn {
  background: orange;
}

.login-container__logo {
  max-height: 12em;
  margin-bottom: 2em;
}

.mobile-login-filler {
  @media (max-width: 599px) {
    background: white;
    height: 4.8rem;
    position: absolute;
    width: 100%;
    top: 0;
  }
}
