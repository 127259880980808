@import '../settings';

/// @name glyph-height
/// The size of a one side of a glyph.
$glyph-size: 7em;
$frail-size: 5em;

/// @name glyph-height
/// The height of a glyph.
$glyph-height: $glyph-size;

/// @name glyph-height
/// The width of a glyph.
$glyph-width: $glyph-size;

// scss-lint:disable ColorVariable

/// @name glyph-ew-colors
/// The border colors which represent the state of the EWS score.
$glyph-ew-colors: (#01cc5d, #7cb904, #eec500, #fc6d00, #cd0000);

$low: nth($glyph-ew-colors, 1);
$medium: nth($glyph-ew-colors, 2);
$high: nth($glyph-ew-colors, 3);
$warning: nth($glyph-ew-colors, 4);
$danger: nth($glyph-ew-colors, 5);

/// @name glyph-bg-colors
/// The possible background colors for a glyph.
$glyph-bg-colors: (#eeeeec, #ffddb7, #ffffba, #beffc8);

/// @name glyph-fg-colors
/// The possible colors for the icons in the foreground of a glyph.
$glyph-fg-colors: (#356883);

// scss-lint:enable ColorVariable

/// @name glyph
/// Attempts to uniquely identify patients using a unique color combination.
@mixin glyph {
  @include glyph-color-variations;
  background: $theme;
  border: solid .4em;
  display: inline-block;
  height: $glyph-height;
  position: relative;
  width: $glyph-width;

  i {
    font-size: $glyph-size;
    left: -.1em;
    position: absolute;
    z-index: 22;
  }


  span {
    bottom: 0.15em;
    font-size: $frail-size;
    left: 0.15em;
    position: absolute;
    z-index: 22;
  }

  .age,
  .pew {
    font-size: .65em;
    position: absolute;
    z-index: 21;
  }

  .pew {
    bottom: -.7em;
    color: $white;
  }

  &:before {
    border-bottom: solid 1em;
    border-left: solid 1em transparent !important;
    border-right: solid 1em transparent !important;
    bottom: -2px;
    content: '';
    display: block;
    font-size: 1.4em;
    height: 0;
    left: -1em;
    position: absolute;
    width: 0;
    z-index: 10;
  }
}

@mixin clickable-glyph {
  @include glyph-color-variations;
}

/// @name glyph-color-variations
/// Rules for all the possible colour variations within glyphs.
@mixin glyph-color-variations {
  @for $i from 1 through length($glyph-fg-colors) {
    &[fg='#{$i}'] {
      .icon {
        color: nth($glyph-fg-colors, $i);
      }
    }
  }

  @for $i from 1 through length($glyph-bg-colors) {
    &[bg='#{$i}'] {
      background: nth($glyph-bg-colors, $i);
    }
  }
}
