@import '../modules/batchsignoff';

.batch-signoff {
  background: $mild-pink;
  border-bottom: solid 2px $very-light-grey;
  border-right: solid 3px $very-light-grey;
  margin-bottom: .75em;
  padding: 1em .5em;
}

.batch-signoff__form {
  background: $white;
  line-height: 2.2em;
  margin: .5em 0;
}

.batch-signoff__form-title {
  color: $jet-black;
  margin-bottom: .8em;
  padding-left: .5em;
}

.batch-signoff__form-details {
  color: $grey-body;
  padding-left: .5em;
}

.batch-signoff__form-nav {
  color: $teal-velvet;
  font-size: .75em;
  text-align: right;
}

.batch-signoff__form-icon {
  color: $unselected;
}

.countersign-button {
  border-bottom: solid 2px $very-light-grey;
  border-left: 0;
  border-right: solid 2px $very-light-grey;
  border-top: 0;
  color: $mild-blue;
  margin: .5em 0 1em 0;
  padding: .5em 1em;
}

.batch-signoff-confirmation {
  font-size: .75em;
  max-width: 32rem;
  padding: 5em 2.5em;
}

.batch-signoff-confirmation__heading {
  color: $brilliant-sky;
  font-size: 1.5em;
}

.batch-signoff-confirmation__heading--mobile {
  margin-top: 1em !important;
  text-align: center;
}

.batch-signoff-confirmation__content {
  color: $grey-body;
  font-size: 1.5em;
  margin-bottom: 2em !important;
  margin-top: 2em !important;
}

.batch-signoff-confirmation__buttons {
  font-size: 1.5em;
}

.batch-signoff-confirmation__button--mobile {
  text-align: center;
}

.batch-signoff-confirmation__button--ok {
  background: $mild-blue;
  border: 0;
  color: $white;
  font-size: .9em !important;
  margin-right: 2em;
  padding: .5em 2.5em !important;

  &:hover {
    background: $mild-blue;
    color: $white;
  }
}

.batch-signoff-confirmation__button--cancel {
  background: $lucene-grey;
  border: 0;
  color: $stone-grey;
  font-size: .9em !important;
  padding: .5em 1.2em !important;

  &:hover {
    background: $lucene-grey;
    color: $stone-grey;
  }
}

.batch-glyph {
  padding-bottom: 0 !important;
  padding-left: 6.5em !important;
}

.batch-glyph--mobile {
}


.batch-patient {
  background: $white;
  border-right: solid 3px $very-light-grey;
  padding: .5em;
}

.batch-patient--open {
  border-bottom: solid 2px $serene-pink;
}

.batch-patient--closed {
  border-bottom: solid 3px $very-light-grey;
  margin-bottom: .5em;
}

.batch-patient__selection-count {
  height: 100%;
  width: 100%;
  font-size: 1.5em;
  margin-top: .3em;

  span {
    bottom: 0;
    position: absolute;
    right: 0;
  }
}

.batch-patient__selection-count--mobile {
  margin-top: .15em;
}

.batch-down-arrow {
  bottom: .85em;
  font-size: 1.1em;
  position: relative;
}

.batch-up-white-arrow {
  bottom: 1.03em;
  font-size: 1.1em;
  position: relative;
}

.batch-up-purple-arrow {
  bottom: 1.72em;
  font-size: 1.1em;
  position: relative;
}
