@import '../settings';
@import '../modules/sign';

.sign {
  @include sign;

  canvas {
    border: solid .1em;
    height: 100%;
    width: 100%;
  }
}

.sign-display {
  height: 11em;
  width: 22em;
}
