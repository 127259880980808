@import '../modules/glyph';

$small-glyph-size: 6em;
$small-glyph-height: $small-glyph-size;
$small-glyph-width: $small-glyph-size;
$very-small-glyph-size: 4em;
$very-small-glyph-height: $very-small-glyph-size;
$very-small-glyph-width: $very-small-glyph-size;


.patient.preview {
  a.clickable-glyph,
  a.clickable-glyph:visited {
    color: #ff0000;
    @include clickable-glyph;
  }
}

.glyph {
  @include glyph;

  &.small {
    height: $small-glyph-height;
    width: $small-glyph-width;

    i {
      font-size: $small-glyph-size;
    }

    .gender {
      font-size: 1.4em;
      left: 2.75em;
      top: .2em;
    }

  }

  &.very-small {
    border-width: .2em;
    height: $very-small-glyph-height;
    width: $very-small-glyph-width;

    i {
      font-size: $very-small-glyph-size;
      left: 0;
    }

    .gender {
      font-size: 1em;
      left: 2.7em;
      top: .2em;
    }

  }

  &.no-ews {
    border-color: $titanium-grey !important;

    &:before {
      border-color: $titanium-grey !important;
      border-left: solid 1em transparent !important;
      border-right: solid 1em transparent !important;
    }
  }

  .gender {
    font-size: 1.5em;
    left: 3.2em;
    top: .2em;
  }
}

