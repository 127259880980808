@import '../settings';
@import 'bourbon';

/// @name glyph-height
/// The radius of a radial progress component.
$radial-progress-radius: 2.5em;

/// @name glyph-height
/// The diameter of a radial progress component.
$radial-progress-diameter: $radial-progress-radius * 2;

/// @name Radial Progress
/// The container for a radial progress meter. The meter
/// itself will be drawn onto an absolutely positioned
/// canvas.
@mixin radial-progress($dimension: $radial-progress-diameter) {
  height: $dimension;
  line-height: $dimension;
  margin: 0 auto;
  position: relative;
  text-align: center;
  width: $dimension;

  canvas {
    left: 0;
    position: absolute;
    top: 0;
  }

  // fix for 2x font awesome icons
  i {
    line-height: 2.6em;
  }

  .adjustright {
    left: .05em;
    position: relative;
  }

  &.orange canvas {
    @include filter(hue-rotate(200deg));
  }

  &.green canvas {
    @include filter(hue-rotate(270deg));
  }
}

