@import '../modules/careplan';

.careplan-modal-heading {
  color: $brilliant-sky;
  font-size: 1.25em;
  font-weight: bold;
  margin-bottom: .5em;
  margin-top: 2em;
}

.careplan-modal-subtext {
  color: $grey-body;
  font-size: .75em;
  line-height: 1.2em;
  margin-bottom: .5em;
}

.careplan-modal-button {
  background: $mild-blue;
  color: #ffffff;
  font-size: 1.25em !important;
  margin-top: 1.5em;
  padding: .2em 2.2em !important;
}

.careplan-heading {
  color: $striking-cyan;
  font-size: 1.25em;
  margin-left: .8em;
  margin-top: .3em;
}

.careplan-subheading {
  color: $striking-cyan;
  cursor: pointer;
}
