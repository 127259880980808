@import '../settings';

.readmit {
  color: $grey-body;
  max-width: 32em;

  .heading {
    color: $brilliant-sky;
    font-size: 1.5em;
  }

  .ok {
    background: $theme;
    color: #ffffff;

    &:hover {
      background: $theme;
      color: #ffffff;
    }
  }

  .cancel {
    background: $lucene-grey;
    color: $stone-grey;

    &:hover {
      background: $lucene-grey;
      color: $stone-grey;
    }
  }

  button.repopulate {
    color: $brilliant-sky;
    padding-left: 2em;
    padding-right: 2em;
  }

  div.form {
    background: #ffffff;
    margin: .5em 0;
    padding: .5em;
  }

  md-card {
    color: $grey-body;

    p.content {
      font-size: .75em;
      line-height: 1.5em;
      margin-bottom: .75em;
    }


    &.forms {
      background: $mild-pink;

      md-card-content {
        padding: .5em;
      }
    }

  }

  i.adjust {
    bottom: 1.57em;
    color: #ffffff;
    font-size: 1.25em;
    left: .65em;
    position: relative;
  }

  .patient-info {
    font-size: .75em;
    padding-left: 2em;
  }

}
