@import '../settings';
@import 'glyph';

/// @name Patient Preview
/// @author Dan Prince
/// The component which gives you an overview of the patient's most
/// critical data.
@mixin patient-preview {
  $dent: #81bb08;
  $ent: #65ccdb;
  $eye: #eb6ec0;
  $gynae: #7223ad;
  $icon: #2ba6cc;
  $invst: #ff6100;
  $orth: #0069cc;
  $padding: 0;
  $surg: #eeb800;
  $text-height: 1.25em;
  $urol: #649e46;
  $xray: #bd6eeb;

  overflow: hidden;
  padding-bottom: .5em;
  padding-left: $glyph-width + .5em;
  padding-right: .5em;
  padding-top: .5em;
  position: relative;

  &.mobile {
    padding-left: 4.5em;


  }

  &.large {
    padding-top: 0;
  }

  .info {
    line-height: 1em;
    min-height: 96px;
  }

  .search-info {
    margin-top: -0.6em;
    padding-left: 1em;

  }

  .dashboardinfo {
    line-height: $text-height;

    .dash-icon {
      min-width: 2.5em;
    }
  }

  &.mobile {
    .dashboardinfo {
      .dash-icon {
        min-width: 1.5em;
      }
    }
  }

  .dash-icon {
    display: inline-block;
    padding-bottom: .5em;
    text-align: center;
    width: 1.8em;
  }

  .lowerize {
    left: .5em;
    position: relative;
    top: .7em;
  }

  .stack-lg {
    height: 1.33333333em;
    line-height: 1.5em;
    vertical-align: top;
  }

  .glyph {
    left: $padding;
    position: absolute;
    top: $padding;
  }

  .name {
    color: $jet-black;
  }

  .dno,
  .updated {
    color: $grey-body;
    font-size: .7em;
    line-height: .6em;
  }

  &.mobile {
    .dno {
      padding-right: 0.2em;
    }

  }

  .dno {
    padding-right: 1.5em;
  }

  .dno-row {
    line-height: 0.8em;
    padding-bottom: .4em;
  }

  .moreinfo {
    left: -($glyph-width + .5em);
    line-height: 100%;
    margin-bottom: -.2em;
    max-height: 2 * $text-height;
    overflow: visible;
    padding-top: 0.2em;

    position: relative;
    width: calc(100% + 7.5em); /// sass doesnt allow variables in calc.

    &.expand {
      overflow: visible;
    }
  }

  &.mobile {
    .moreinfo {
      left: -4.5em;
      padding-right: 3em;
    }
  }

  .nowrap,
  .patientname {
    overflow: hidden;
    white-space: nowrap;

  }

  .patientname {
    color: $jet-black;
    padding-bottom: 0.3em;
    text-overflow: ellipsis;
  }
}

