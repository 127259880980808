@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?8hqosb');
  src:  url('fonts/icomoon.eot?8hqosb#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?8hqosb') format('truetype'),
    url('fonts/icomoon.woff?8hqosb') format('woff'),
    url('fonts/icomoon.svg?8hqosb#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Frailty-01-NG:before {
  content: "\e900";
}
.icon-Frailty-02-NG:before {
  content: "\e901";
}
.icon-Frailty-03-NG:before {
  content: "\e902";
}
.icon-Frailty-04-NG:before {
  content: "\e903";
}
.icon-Frailty-05-NG:before {
  content: "\e904";
}
.icon-Frailty-06-NG:before {
  content: "\e905";
}
.icon-Frailty-07-NG:before {
  content: "\e906";
}
.icon-Frailty-08-NG:before {
  content: "\e907";
}
.icon-Frailty-09-NG:before {
  content: "\e908";
}
.icon-Frailty-10-NG:before {
  content: "\e909";
}
.icon-Death-NG:before {
  content: "\e910";
}
.icon-uniE606:before {
  content: "\e606";
}
.icon-uniE607:before {
  content: "\e607";
}
.icon-uniE608:before {
  content: "\e608";
}
.icon-uniE609:before {
  content: "\e609";
}
.icon-uniE60a:before {
  content: "\e60a";
}
.icon-uniE60b:before {
  content: "\e60b";
}
.icon-uniE60c:before {
  content: "\e60c";
}
.icon-uniE60d:before {
  content: "\e60d";
}
.icon-uniE60e:before {
  content: "\e60e";
}
.icon-uniE60f:before {
  content: "\e60f";
}
.icon-uniE610:before {
  content: "\e610";
}
.icon-uniE611:before {
  content: "\e611";
}
.icon-Baby-NoGender:before {
  content: "\e700";
}
.icon-Toddler-NoGender:before {
  content: "\e701";
}
.icon-Child-NoGender:before {
  content: "\e702";
}
.icon-friend-family:before {
  content: "\e800";
}
.icon-person-unknown:before {
  content: "\e801";
}
