@import '../settings';

.button-area {
  display: block;
  text-align: center;

  .import {
    margin-bottom: 1em;
    margin-top: .5em;
  }
}
