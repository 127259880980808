@import '../settings';
@import 'glyph';

$card-glyph-diameter: 2rem;

/// @name card-glyph
/// @author Dan Prince /// A glyph which represents the patient's age, gender and PEW score.
/// Attempts to uniquely identify patients using a unique color combination.
@mixin card-glyph {
  @include shape-variations;
  @include color-variations;
  background: $theme;
  border-radius: 50%;
  display: inline-block;
  height: $card-glyph-diameter;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: $card-glyph-diameter;

  &:before {
    color: $white;
    font-family: 'icomoon';
    font-size: 1.5rem;
    line-height: $card-glyph-diameter;
    position: relative;
    vertical-align: -.35em;
    z-index: 10;
  }

  &:after {
    background: $theme-dark;
    content: '';
    display: inline-block;
    height: $card-glyph-diameter / 1.5;
    left: .3rem;
    margin-top: .2rem;
    position: absolute;
    top: .1rem;
    width: $card-glyph-diameter / 1.5;
    z-index: 5;
  }

  &[l1='2']:after {
    // circle
    border-radius: 50%;
  }

  &[l1='1']:after {
    // square
    border-radius: 0;
  }
}

@mixin shape-variations {
  $shapes: '\e606', '\e607', '\e608', '\e609', '\e60a',
  '\e60b', '\e60c', '\e60d', '\e60e', '\e60f',
  '\e610', '\e611';

  @for $i from 1 to length($shapes) {
    &[l2='#{$i}']:before {
      content: nth($shapes, $i);
    }
  }
}

@mixin color-variations {
  // scss-lint:disable ColorVariable
  $colors: #56a1b0, #eb6ec0, #0069cc, #01cc5d, #7cb904, #eec500, #fc6d00, #cd0000;
  // scss-lint:enable ColorVariable

  @for $i from 1 to length($colors) {
    &[color='#{$i}']:after {
      background: nth($colors, $i);
    }
  }
}
