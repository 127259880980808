@import '../modules/assessment';

.assessment {
  background: $light-grey;
  margin-bottom: -.5em;
  padding-top: .5em;

  button {
    margin-bottom: 0;
    margin-top: .5em;
  }
}

.instructions {
  background: $white;
  margin-bottom: -2.5em;
  margin-top: 3em;
  padding: .5em;
}

.history {
  color: $white;
  margin: 3em .5em -2.5em 0;
  text-align: right;

  &.signoff {
    padding-left: .5em;
    text-align: left;
  }

  a {
    color: $white !important;
  }
}

.section-bg {
  height: .1em;
  margin: .5em 0 0;
}

.separator-bg {
  height: .1em;
  margin: .75em 0;
  background: $teal-velvet;
}

.relative {
  position: relative;
}

.warning {
  bottom: .5em;
  position: absolute;
  right: .25em;

  &.increase-bottom {
    bottom: .7em;
  }

  &.zero-top {
    top: 0;
  }

  &.to-right {
    right: -1.4em;
  }

  .unit-right {
    right: -1em;
  }
}
