@import '../modules/title-bar';

.title {
  @include title-bar;

  &.phone {
    font-size: 1em;
    padding: .35em .5em;
  }

}

.group-title {
  background: $light-grey;
  margin-top: .2em;
  text-align: center;
}

.back-to-form {
  font-size: .8em;
  margin-top: -2em;
  padding-bottom: .2em;
  padding-right: 1em;
  text-align: right;
}
