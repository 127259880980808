@import '../settings';
@import '../modules/panel';
@import '../modules/external';

.card {
  @include panel($white);
  @include floating;
  $kumquat-orange: #f57900;
  $rep-completed-strip: #44b0d1;
  $rep-completed-text: #56a1b0;
  $rep-due-strip: #73d216;
  $rep-due-text: #4e9a06;
  $rep-overdue-text: #a40000;
  $rep-warn-strip: #ff7400;


  color: $theme-dark;
  margin-bottom: .5em;
  min-height: 1.5em;
  overflow: hidden;
  padding-left: .25em !important;
  padding-right: .25em !important;
  position: relative;

  .action-alert {
    background-color: $cream;
    color: $grey-body;
    padding: .2em;

    i {
      padding: .3em;
    }
  }


  .card {
    width: auto;
  }

  // scss-lint:disable all
  label[btf-markdown] p {
    margin: 0;
  }

  // scss-lint:enable all

  section {
    margin-top: 3em;
  }

  header {
    cursor: pointer;
    left: 0;
    overflow: hidden;
    padding: 1em;
    position: absolute;
    top: 0;
    width: 100%;

    .heading {
      color: $jet-black;
      margin-left: .7em;
      margin-top: .28em;
    }

    .repeating-heading {
      margin-left: .4em;
    }

    &.nopadding {
      padding: 0;
    }


    &:hover {
      background: rgba(255, 255, 255, .2);
    }


    .right {
      bottom: .3em;
      position: relative;
    }

    .due {
      text-align: right;
    }

    .rep-overdue {
      color: $rep-overdue-text;

      i {
        color: $blood-red;
      }
    }

    .rep-warn {
      color: $kumquat-orange;

      i {
        color: $rep-warn-strip;
      }
    }

    .rep-due {
      color: $rep-due-text;

      i {
        color: $rep-due-strip;
      }
    }

    .rep-completed {
      color: $rep-completed-text;

      i {
        color: $rep-completed-strip;
      }
    }

    .strip {
      float: right;
      width: .5em;
    }

    .overdue-strip {
      background: $blood-red;
      height: 3em;
    }

    .warn-strip {
      background: $rep-warn-strip;
      height: 3em;
    }

    .due-strip {
      background: $rep-due-strip;
      height: 3em;
    }

    .completed-strip {
      background: $rep-completed-strip;
      height: 3em;
    }

    .rep-heading-container {
      display: inline-block;
      width: 99%;
    }

    .rep-heading-container-phone {
      display: inline-block;
      width: 97%;
    }


  }

  header.disabled {
    background-color: $disabled;

    &:hover {
      background-color: $disabled;
    }
  }

  &.static {
    box-shadow: none;

    header {
      background: $white;
      color: $theme-dark;
      padding: .6em;

    }

    header.completed {
      background: $success;
    }


    header.otheruser {
      background: $teal-velvet;
    }

    header.external {
      background: lighten($external, 20%);
    }

    section {
      background: $white;
      margin-top: 3.5em;
      padding: .5em;
    }

    form section {
      margin-top: 0;
      padding: 0;
    }

    .card section {
      margin-top: 2em;
    }

    .topmargin {
      margin-top: .3em;
    }

    .submit {
      margin: .4em 0 -.4em;
    }

  }


  &.repeating {
    box-shadow: none;
    color: $jet-black;
    padding-bottom: .5em;

    header {
      background: $white;
    }

    section {
      background: $white;
      padding: .5em;
    }
  }
}
