@font-face {
  font-family: 'icomoon3';
  src:
    url('fonts/icomoon3.ttf?qf53er') format('truetype'),
    url('fonts/icomoon3.woff?qf53er') format('woff'),
    url('fonts/icomoon3.svg?qf53er#icomoon3') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon3-"], [class*=" icon3-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon3' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon3-countersign-rejected:before {
  content: "\e90a";
}
.icon3-System-Notes:before {
  content: "\e909";
}
.icon3-readmission-half:before {
  content: "\e905";
  color: #b3b3b3;
}
.icon3-readmission-full:before {
  content: "\e908";
  color: #ff7400;
}
.icon3-alarm-clock:before {
  content: "\e901";
}
.icon3-radio-checked:before {
  content: "\e900";
}
.icon3-radio-blank:before {
  content: "\e902";
}
.icon3-allergies-active .path1:before {
  content: "\e903";
  color: rgb(70, 177, 210);
}
.icon3-allergies-active .path2:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(235, 197, 0);
}
.icon3-allergies-active .path3:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon3-allergies-inactive .path1:before {
  content: "\e904";
  color: rgb(204, 204, 204);
}
.icon3-allergies-inactive .path2:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(102, 102, 102);
}
.icon3-allergies-inactive .path3:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon3-safeguarding-inactive:before {
  content: "\e906";
  color: #ccc;
}
.icon3-safeguarding-active:before {
  content: "\e907";
  color: #46b1d2;
}
