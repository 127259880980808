@import '../settings';


/// @name search
/// Patient Search UI


$theme: #2ba6cc;
$transparent-grey: #ddd;
$light-pink: #fbe3e3;
