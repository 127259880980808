@import '../modules/search';

a.grey {
  color: $grey-body;
}

.scrollable-container {
  height: 98%;
}

.scrollable {
  min-height: calc(100vh - 6em);
  overflow-y: auto;
}

.preview-row {
  margin-left: 0 !important;
}

.button-row {
  text-align: right;
}

#results-content {
  padding-left: 0.5em;
}

.selected-patient {
  background: #ffffff;
  border-width: 2px 0 2px 0;
  border-style: solid;
  margin-top: -2px;
  position: relative;
  z-index: 25;

  &.row {
    margin-right: calc(-0.9375em - 2px) !important;
  }
}

.search-form {
  padding: .5em;
}

.search-form__button {
  text-align: right;
}

.search-results__title {
  font-size: 1.25em;
  padding: 0 .5em .5em;
}

.search-results__advice {
  color: $grey-body;
  padding: .25em .9375em;
}

.search-results__warning {
  background: $light-pink !important;
  color: $danger;
}

.patient-preview-large {
  padding: .5em 0;
}

hr {
  background-color: $transparent-grey;
  border: 0;
  color: $transparent-grey;
  height: 1px;
  margin: .5em 1em -.5em 1em;
  text-align: center;
}

.selected-patient-preview .row {
  margin-left: 0 !important;
}

.result-info {

  border-width: 0 0 0 2px;
  border-style: solid;
  min-height: calc(100vh - 6em);
  z-index: 1;

  .right {
    text-align: right;
  }

  h3 {
    font-size: 1.25em;
    margin-top: 1em;
  }

  .messagetitle {
    display: none;
  }

  a.grey {
    color: $grey-body;
  }

  .row {
    .row {
      margin: 0 -.4375rem;
    }
  }
}

.result-info__dashlink {
  font-size: .8em;
  padding-left: 2em;
}

.result-info__question {
  color: $grey-body;
  font-size: .9em;
  margin-top: 1em;
}

.result-info__answer {
  font-size: 1.3em;
}

.result-info__answer--disabled {
  color: $disabled;
}

.mini-results-panel {
  background: $light-grey;
}

.row {
  &.add-patient {
    margin-left: -.5em;
    margin-top: 1em;
  }
}

.view-container {
  display: inline-block;
  padding-bottom: .5em;
  position: relative;

  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: #ffffff;
    border: 0;
    box-shadow: -13px 13px 0 -12px $very-light-grey, 13px 13px 0 -12px $very-light-grey;
    display: inline-block;
    margin-left: 0.5em;
    outline: none;
    padding: 0.7em 0.5em;

    option {
      padding: 0.7em 0.5em;
    }
  }
}


.view-container:after {
  bottom: 10px;
  content: "▼";
  pointer-events: none;
  position: absolute;
  right: 15px;
}

.large-preview-icons {
  margin-top: 4px;
}

.add-view {
  padding: .25em .5em;
}

.view-header {
  color: $grey-body;
  font-size: .75em;
  margin: .5em 0;
}

.search-overview {

  background: #ffffff;
  border-bottom: solid 2px $very-light-grey;

  .small-1 {
    color: $theme;
    margin-top: .3em;
    padding-left: 0.5em;
    padding-right: 0;
  }

  .small-11 {
    background: #ffffff;
    padding-left: 0.2em;
    padding-right: 0.25em;

    input {
      background: #ffffff;
      box-shadow: -13px 13px 0 -12px $very-light-grey, 13px 13px 0 -12px $very-light-grey;
      padding: .4em;
    }
  }
}
