@import 'bourbon';
@import './settings';

.hidden {
  visibility: hidden;
}

.right {
  text-align: right;
}

.topmargin {
  margin-top: .2em;
}

.rightmargin {
  margin-right: .2em;
}

.leftmargin {
  margin-left: .2em;
}

.center {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

.italicize {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

.silver-grey {
  color: $silver-grey !important;
}

.grey-body {
  color: $grey-body !important;
}

.theme-color {
  color: $theme;
}

.theme-bg {
  background: $theme;
}

.teal-velvet {
  color: $teal-velvet;
}

.teal-velvet-bg {
  background: $teal-velvet;
}

.striking-cyan {
  color: $striking-cyan;
}

.aluminium-grey {
  color: $aluminium-grey;
}

.pale-wisteria {
  color: $pale-wisteria !important;
}


.medium-wisteria {
  color: $medium-wisteria;
}

.brilliant-sky {
  color: $brilliant-sky;
}

.nodisplay {
  display: none;
}

.move-left {
  padding-right: 0;
  right: 2.5em;
}

.right-margin {
  right: .5em;
}

.unit-left {
  right: 1em;
}

.ml-checkbox {
  right: 2em;
}

.selected-true {
  color: $check !important;
}

.no-horizontal-margin {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.no-horizontal-padding {
  padding-left: 0;
  padding-right: 0;

}

.no-left-margin {
  margin-left: 0;
}

.noboxshadow {
  box-shadow: none !important;
}

.noleftpadding {
  padding-left: 0 !important;
}

.nohorizontalpadding {
  padding-left: 0 !important;
  padding-right: 0 !important;

}

.mildleftpadding {
  padding-left: .2em !important;
}

.subhead {
  background: $white;
  color: $theme-dark;
  font-weight: bold;

  &.trainee {
    color: $studio-purple;
  }
}

.white {
  background: $white;
}

.white-bg {
  color: $white;
}

[tabindex] {
  outline: none;
}

.middleverticalalign {
  vertical-align: middle;
}

.warn {
  color: $kumquat-orange !important;
}

.overdue {
  color: $postbox-red !important;
}

.padding-1em {
  padding: .8em;
}

.padding-halfem {
  padding: .5em;
}

.va-super {
  vertical-align: super;
}

.va-sub {
  vertical-align: sub;
}

.va-bottom {
  vertical-align: bottom;
}

.right-padding {
  padding-right: 0.5em;
}

.no-right-padding {
  padding-right: 0;
}


.no-right-margin {
  margin-right: 0;
}

.top-padding {
  padding-top: 0.5em;
}

.align-right {
  text-align: right;
}

.no-bottom-margin {
  margin-bottom: 0;
}

.auto-margin {
  margin: auto;
}

.no-top-margin {
  margin-top: 0;
}

.no-bottom-padding {
  padding-bottom: 0 !important;
}

.no-vertical-padding {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.left-padding {
  padding-left: 0.5em;
}

.mild-line-height {
  line-height: 1.25em;
}

.full-width {
  width: 100%;
}

.trainee-colored {
  color: $studio-purple !important;
}

.trainee-bg {
  background: $studio-purple !important;
}

.trainee-light {
  color: $theme-trainee-light !important;
}


.trainee-light-bg {
  background: $theme-trainee-light !important;
}

.trainee-mild {
  color: $theme-trainee-mild !important;
}

.no-clicks {
  pointer-events: none;
}

.trainee-button {
  background: $theme-trainee-light !important;
  border: 0 !important;
  color: #ffffff !important;

  &:hover {
    background: #ffffff !important;
    border: solid 1px $theme-trainee-light !important;
    color: $theme-trainee-light !important;
  }
}

.trainee-anchor {
  color: $theme-trainee-light;

  &:hover {
    color: $trainee-hover;
  }
}

.no-border {
  border: 0 !important;
}

.gray-out {
  color: #999;
}

.progress-indicator {
  background-color: transparent;
  box-shadow: none;
  height: 8em;
}

md-switch .md-thumb::before {
  content: normal;
}

.pointer {
  cursor: pointer;
}

.exception {
  background: #ffffff;
  padding: 2em;
  font-size: .9rem;

  margin-top: -3.8rem;

  h3 {
    font-size: 1.2rem;
  }

  p {
    font-size: .9rem;
    line-height: 1.5rem;
    padding-bottom: .5rem;
  }

  .version-info {
    background: $pale-cyan;
  }

  .trace {
    background: $malachite-grey;
    margin: 1em 0;
    padding: 1em;
  }
}

.md-dialog-container {
  height: 100% !important;
  position: fixed !important;
  top: 0 !important;
}

.wrap {
  overflow: visible;
}

md-toast.md-bottom {
  left: 50%;
  position: fixed;
  transform: translate3d(-50%, 0, 0);
}

.hno-height {
  height: 1.2em;
}

.notification-dot {
  color: $postbox-red;
  font-size: .5em;
  left: 4.75em;
  position: absolute;
  top: 2em;
}

.audio-field .microphone {
  background: $pale-cyan;
}

.audio-field .md-disabled-thumb {
  border-color: $teal-velvet;
  background-color: $teal-velvet;
}

md-steppers {
  height: 100vh;
}

md-pagination-wrapper {
  pointer-events: none !important;
}

.access_code {

}
