// scss-lint:disable all
@charset "UTF-8";
// scss-lint:enable all
@import '../settings';
@import 'bourbon';

$cross: #d10600;
/// @name Checkbox
/// @author Dan Prince
/// A checkbox component.
@mixin checkbox {
}

@mixin custom-checkbox {
  @include user-select(none);
  cursor: pointer;

  .checkbox {
    color: $grey-body;
    float: left;
    margin: .1em;
  }

  .selected-false {
    color: $cross;
  }
}

