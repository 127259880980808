$theme: #4fd1e7;
$theme-dark: #6aafbf;
$white: #fff;
$jet-black: #000;
$light-grey: #f1f2f2;
$red: #f00;
$grey: #d1d2d4;
$dark-grey: #56565b;
$grey-body: #666;
$silver-grey: #ccc;
$teal-velvet: #56a1b0;
$greyish-blue: #cccccf;
$brilliant-sky: #65ccdb;
$aluminium-grey: #aaa;
$very-light-grey: #cdcdcd;
$check: #81bb08;
$striking-cyan: #44b0d1;
$postbox-red: #d10600;
$kumquat-orange: #f57900;
$studio-purple: #8949b4;
$theme-trainee-light: #b289d4;
$theme-trainee-contrast: #dfb6fa;
$theme-trainee-mild: #ac7bcc;
$anchor_hover: #258faf;
$trainee-hover: #7000cd;
$theme-hover: #50b5d4;

$pale-cyan: #dcfaff;
$malachite-grey: #babdb6;
$pale-wisteria: #b893d7;
$medium-wisteria: #9e46e7;
$mercury-grey: #999;
$titanium-grey: #888a85;
$nearly-black: #2e3436;
$pale-iris: #ce96ff;
$desaturated-violet: #8f5bb3;
$cream: #fffecc;
$pale-violet: #eedaff;
$pale-lavender: #d3a1ff;
$intense-violet: #47067a;
$desaturated-cyan: #a6d3d9;
$strong-cyan: #2ba6cb;

$success: #5abf5c;
$warning: #f3732c;
$danger: #e74f4f;
$external: #ffa605;
$disabled: #d1d2d4;

$mild-pink: #eee7f4;
$mild-blue: #45b1d3;
$mild-white: #eeeeec;
$lucene-grey: #d3d7cf;
$stone-grey: #555753;
$blood-red: #c00;

@mixin outline($color: $grey) {
  border: solid 1px $color;
  border-radius: .25em;
}

@mixin shadowed($depth: .1) {
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, $depth);
}

@mixin overflow {
  box-sizing: content-box;
  padding-bottom: 1em;
  padding-left: 2em !important;
  padding-right: 2em !important;
  padding-top: 1em;
}
