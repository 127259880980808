@import '../settings';

.about-panel {
  height: 80%;
  overflow-y: scroll;
  scrollbar-width: none;
}

.about-panel::-webkit-scrollbar {
  display: none;
}

.version-info {
  padding: 1em;
  color: $grey-body !important;
}

.version-info__content {
  color: $grey-body;
  word-wrap: break-word;
}

.version-info__expanded {
  margin-top: 1em;
}

.support-button {
  margin: 1em;
  text-align: center;
}

.support-button button {
  font-size: 1.25em;
  padding: 1em;
  white-space: normal;
  line-height: 1.25em;

  @media (max-width: 960px) {
    font-size: 1em;
  }
}

.about-subheading {
  line-height: 1.2em;
  padding: .75em 0;
}

.footer {
  bottom: -2em;
  padding: 1em .5em 0;
  position: relative;
}

.footer__separator {
  margin: .75em 0;
}

.footer__subinfo {
  font-size: .9em;
}


.footer__link {
  color: $grey-body;
  cursor: pointer;

  &:hover {
    color: $theme-hover;
  }

  a, a:visited {
    color: $grey-body;
  }

  a:hover {
    color: $theme-hover;
  }
}

.footer__links {
  margin: auto;
  max-width: 50em;

  @media (max-width: 58em) {
    padding-left: 4em;
  }
}

.footer__logo {
  position: absolute;
  cursor: pointer;
}

.chai-logo {
  font-size: 5em;
}

.about__tag {
  color: $grey-body;
  background: $silver-grey;
}

md-chips .md-chips {
  box-shadow: none;
}

.about__arrow {
  text-align: center;

  span {
    font-size: 1.1em;
    position: relative;
  }
}
