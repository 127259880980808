@import '../settings';

/// @name Label
/// @author Dan Prince
/// A label element which can be used in forms to
/// provide additional information or direction.
@mixin label($color: $grey-body) {
  color: $color;
  display: inline-block;

  &.blur {
    color: $very-light-grey;
  }
}

@mixin alt-label($color: $theme) {
  background: $color;
  border-radius: .25em;
  color: $white;
  margin-bottom: .25em;
  padding: .2em .5em;
}
