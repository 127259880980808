// scss-lint:disable all
@charset "UTF-8";
// scss-lint:enable all
@import '../settings';
@import 'checkbox';

/// @name Radio Button
/// @author Dan Prince
/// A radio button component.
@mixin radio-button {
  @include checkbox;
  margin-right: .8em;

  &:before {
    border-radius: 50%;
    content: '●';
    font-size: 1em;
  }

  &:checked:before {
    content: '●';
  }
}
