@import '../modules/text-input';
@import '../modules/text-area';
@import '../modules/checkbox';
@import '../modules/radio-button';
@import '../modules/label';

.form-group-nav {

  font-size: 1.3em;
  padding-top: 0.2em;
  text-align: center;

  a {
    display: inline-block;
    margin-left: .2em;
    margin-right: .2em;
    text-align: center;
  }

  i {
    padding-left: .1em;
    padding-right: .1em;
  }

  .activegroup {
    border: .1em solid;
  }
}

input[type='text'] {
  @include text-input;
  -webkit-appearance: none;
  border: 0;
  box-shadow: -13px 13px 0 -12px $very-light-grey, 13px 13px 0 -12px $very-light-grey;

  &:disabled {
    background-color: $disabled;
    box-shadow: none;
  }
}


input[type='password'] {
  @include text-input;
  -webkit-appearance: none;
  border: 0;
  box-shadow: -13px 13px 0 -12px $very-light-grey, 13px 13px 0 -12px $very-light-grey;

  &:disabled {
    background-color: $disabled;
    box-shadow: none;
  }
}

::placeholder {
  color: $silver-grey;
  font-style: italic;
}

input[type='number'] {
  @include text-input;
  -webkit-appearance: none;
  border: 0;
  box-shadow: -13px 13px 0 -12px $very-light-grey, 13px 13px 0 -12px $very-light-grey;

  &:disabled {
    background-color: $disabled;
    box-shadow: none;
  }
}

input[type='number'].short {
  width: 4em;
}

input.md-datepicker-input {
  box-shadow: -13px 13px 0 -12px $very-light-grey, 13px 13px 0 -12px $very-light-grey;
  padding: .5em;
}

div.md-datepicker-focused {
  border-bottom-width: 0 !important;
}


input[type='tel'] {
  @include text-input;
  -webkit-appearance: none;
  border: 0;
  box-shadow: -13px 13px 0 -12px $very-light-grey, 13px 13px 0 -12px $very-light-grey;
}

textarea {
  @include text-area;
  -webkit-appearance: none;
  border: 0;
  box-shadow: -34px 34px 0 -33px $very-light-grey, 34px 34px 0 -33px $very-light-grey;
  font-family: sans-serif;
  min-height: 5rem;
}

input[type='checkbox'] {
  @include checkbox;
}

.checkbox-container {
  @include custom-checkbox;
}

input[type='radio'] {
  @include radio-button;
}

label {
  @include label;

  &.success {
    @include label($success);
  }

  &.warning {
    @include label($warning);
  }

  &.danger {
    @include label($danger);
  }
}

label.alt {
  @include alt-label;

  &.success {
    @include alt-label($success);
  }

  &.warning {
    @include alt-label($warning);
  }

  &.danger {
    @include alt-label($danger);
  }
}

form .field {
  color: $jet-black;
  margin-bottom: .5em;
}

input.time-input {
  margin-bottom: 0;
  padding: 0;
  text-align: center;
  width: 1.5em;
}

.field__expanded {
  background: #f2f2f2;
}

summary.text_instructions {
  color: $grey-body;
}

details {
  margin-bottom: .75em;
}

summary {
  list-style: none
}

summary::-webkit-details-marker {
  display: none;
}

summary:hover {
  cursor: pointer;
}

details summary::before {
  font-family: "Font Awesome 5 Pro";
  content: "\f105";
}

details[open] summary::before {
  font-family: "Font Awesome 5 Pro";
  content: "\f107";
}
