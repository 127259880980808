@import '../settings';
@import '../modules/navbar';

$title-bar-height: 2.3rem;

/// The bar that contains the icons for navigating to the dashboard
/// and leaving the application.
@mixin title-bar {
  background: $dark-grey;
  display: block;
  font-size: 1.3em;
  height: $title-bar-height;
  padding: .25em .5em;
  position: fixed;
  top: $nav-bar-height;
  width: 100%;
  z-index: 30;
}
