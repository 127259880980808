@font-face {
  font-family: 'icomoon1';
  src:
    url('fonts/icomoon1.ttf?2vsytt') format('truetype'),
    url('fonts/icomoon1.woff?2vsytt') format('woff'),
    url('fonts/icomoon1.svg?2vsytt#icomoon1') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon1-"], [class*=" icon1-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon1' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon1-user-arrow-in-right:before {
  content: "\e925";
}
.icon1-hand-holding-user-solid:before {
  content: "\e924";
}
.icon1-expand-arrow-down-blue .path1:before {
  content: "\e907";
  color: rgb(220, 250, 255);
}
.icon1-expand-arrow-down-blue .path2:before {
  content: "\e908";
  margin-left: -1.7236328125em;
  color: rgb(86, 161, 176);
}
.icon1-expand-arrow-down-default .path1:before {
  content: "\e909";
  color: rgb(241, 242, 242);
}
.icon1-expand-arrow-down-default .path2:before {
  content: "\e90a";
  margin-left: -1.5517578125em;
  color: rgb(205, 205, 205);
}
.icon1-expand-arrow-down-default .path3:before {
  content: "\e90b";
  margin-left: -1.5517578125em;
  color: rgb(255, 255, 255);
}
.icon1-expand-arrow-down-default .path4:before {
  content: "\e90c";
  margin-left: -1.5517578125em;
  color: rgb(223, 223, 223);
}
.icon1-expand-arrow-down-singlebatch-bottom .path1:before {
  content: "\e90d";
  color: rgb(241, 242, 242);
}
.icon1-expand-arrow-down-singlebatch-bottom .path2:before {
  content: "\e90e";
  margin-left: -1.5517578125em;
  color: rgb(205, 205, 205);
}
.icon1-expand-arrow-down-singlebatch-bottom .path3:before {
  content: "\e90f";
  margin-left: -1.5517578125em;
  color: rgb(238, 231, 244);
}
.icon1-expand-arrow-down-singlebatch-bottom .path4:before {
  content: "\e910";
  margin-left: -1.5517578125em;
  color: rgb(197, 181, 209);
}
.icon1-expand-arrow-down-singlebatch-top .path1:before {
  content: "\e911";
  color: rgb(209, 180, 232);
}
.icon1-expand-arrow-down-singlebatch-top .path2:before {
  content: "\e912";
  margin-left: -1.5517578125em;
  color: rgb(255, 255, 255);
}
.icon1-expand-arrow-down-singlebatch-top .path3:before {
  content: "\e913";
  margin-left: -1.5517578125em;
  color: rgb(238, 231, 244);
}
.icon1-expand-arrow-down-singlebatch-top .path4:before {
  content: "\e914";
  margin-left: -1.5517578125em;
  color: rgb(223, 223, 223);
}
.icon1-expand-arrow-up-blue .path1:before {
  content: "\e915";
  color: rgb(220, 250, 255);
}
.icon1-expand-arrow-up-blue .path2:before {
  content: "\e916";
  margin-left: -1.1943359375em;
  color: rgb(86, 161, 176);
}
.icon1-expand-arrow-up-blue .path3:before {
  content: "\e917";
  margin-left: -1.1943359375em;
  color: rgb(255, 255, 255);
}
.icon1-expand-arrow-up-default .path1:before {
  content: "\e918";
  color: rgb(241, 242, 242);
}
.icon1-expand-arrow-up-default .path2:before {
  content: "\e919";
  margin-left: -1.1318359375em;
  color: rgb(205, 205, 205);
}
.icon1-expand-arrow-up-default .path3:before {
  content: "\e91a";
  margin-left: -1.1318359375em;
  color: rgb(255, 255, 255);
}
.icon1-expand-arrow-up-default .path4:before {
  content: "\e91b";
  margin-left: -1.1318359375em;
  color: rgb(223, 223, 223);
}
.icon1-expand-arrow-up-singlebatch-bottom .path1:before {
  content: "\e91c";
  color: rgb(241, 242, 242);
}
.icon1-expand-arrow-up-singlebatch-bottom .path2:before {
  content: "\e91d";
  margin-left: -1.1318359375em;
  color: rgb(205, 205, 205);
}
.icon1-expand-arrow-up-singlebatch-bottom .path3:before {
  content: "\e91e";
  margin-left: -1.1318359375em;
  color: rgb(238, 231, 244);
}
.icon1-expand-arrow-up-singlebatch-bottom .path4:before {
  content: "\e91f";
  margin-left: -1.1318359375em;
  color: rgb(197, 181, 209);
}
.icon1-expand-arrow-up-singlebatch-top .path1:before {
  content: "\e920";
  color: rgb(209, 180, 232);
}
.icon1-expand-arrow-up-singlebatch-top .path2:before {
  content: "\e921";
  margin-left: -1.1318359375em;
  color: rgb(255, 255, 255);
}
.icon1-expand-arrow-up-singlebatch-top .path3:before {
  content: "\e922";
  margin-left: -1.1318359375em;
  color: rgb(223, 223, 223);
}
.icon1-expand-arrow-up-singlebatch-top .path4:before {
  content: "\e923";
  margin-left: -1.1318359375em;
  color: rgb(238, 231, 244);
}
.icon1-chai-logo .path1:before {
  content: "\e900";
  color: rgb(76, 83, 86);
}
.icon1-chai-logo .path2:before {
  content: "\e901";
  margin-left: -0.70703125em;
  color: rgb(76, 83, 86);
}
.icon1-chai-logo .path3:before {
  content: "\e902";
  margin-left: -0.70703125em;
  color: rgb(101, 204, 219);
}
.icon1-chai-logo .path4:before {
  content: "\e903";
  margin-left: -0.70703125em;
  color: rgb(101, 204, 219);
}
.icon1-chai-logo .path5:before {
  content: "\e904";
  margin-left: -0.70703125em;
  color: rgb(101, 204, 219);
}
.icon1-chai-logo .path6:before {
  content: "\e905";
  margin-left: -0.70703125em;
  color: rgb(101, 204, 219);
}
.icon1-chai-logo .path7:before {
  content: "\e906";
  margin-left: -0.70703125em;
  color: rgb(101, 204, 219);
}
