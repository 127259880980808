@import '../modules/notes';

.messagetitle {
  background: $teal-velvet;
  color: $white;
  line-height: 1.5em;
  padding: 0 .5em;

}

.print {
  color: $teal-velvet;
  float: right;
  margin-right: .7em;
  text-align: right;
}

.reason {
  color: $nearly-black;
}

.notes, .countersign-note {

  background: $white;
  color: $grey-body;
  line-height: 1.2em;
  padding: 0 .5em;

  .topmargin {
    margin-top: .35em;
  }

  .striking-cyan {
    color: $striking-cyan;
  }

  img {
    border: 2px solid $aluminium-grey;
    padding: .5em;
  }

}


.notes__text-note {
  background: $cream;
  border: 3px solid $silver-grey;
  margin-top: .35em;
  padding: .5em .5em .7em;
  position: relative;
  word-wrap: break-word;

}

.notes__text-note--reject {
  border-color: $aluminium-grey;
  text-decoration: line-through;
  text-decoration-color: $mercury-grey;

  &::before {
    border-color: $aluminium-grey !important;
  }

  &::after {
    border-color: $aluminium-grey !important;
  }
}

.notes__text-note--countersign-reqd.notes__text-note--countersign-view {
  border-color: $pale-wisteria;

  &::before {
    border-color: $pale-wisteria !important;
  }

  &::after {
    background: $pale-cyan !important;
    border-color: $pale-wisteria !important;
  }
}

.notes__text-note--self {
}

.notes__text-note--self::before {
  border-style: solid;
  border-width: 3px 0 0 3px;
  bottom: 0;
  content: '';
  height: .65em;
  position: absolute;
  right: 0;
  width: .6em;
}

.notes__text-note--self::after {
  background: $white;
  border-style: solid;
  border-width: 3px 0 0;
  bottom: -.41em;
  content: '';
  height: .7em;
  position: absolute;
  right: -.41em;
  transform: rotate(-45deg);
  width: .8em;
}


.notes__text-note--other::before {
  border-color: $silver-grey;
  border-style: solid;
  border-width: 3px 3px 0 0;
  bottom: 0;
  content: '';
  height: .65em;
  left: 0;
  position: absolute;
  width: .6em;
}

.notes__text-note--other::after {
  background: $white;
  border-color: $silver-grey;
  border-style: solid;
  border-width: 3px 0 0;
  bottom: -.41em;
  content: '';
  height: .7em;
  left: -.41em;
  position: absolute;
  transform: rotate(40deg);
  width: .8em;
}

.notes__info {
  color: $grey-body;
  font-size: .7em;
  margin-top: .5em;
}

.notes__info__time {
  float: right;
  font-style: italic;
  text-align: right;
}

.notes__info__author--self {
  font-weight: bold;
}

.notes__separator {
  color: $nearly-black;
  display: flex;
  flex-direction: row;
  font-style: italic;

  &:before, &:after {
    border-bottom: 1px solid $nearly-black;
    content: '';
    flex: 1 1;
    margin: auto;
  }

  &:before {
    margin-right: 1em;
  }

  &:after {
    margin-left: 1em;
  }

}

.notes__separator--nodate {
  &:before, &:after {
    border-color: $silver-grey;
    margin-left: 0;
    margin-right: 0;
  }
}


.notes__task-completed {
  color: $titanium-grey !important;
}


.notes__review-link {
  color: $studio-purple;
}

.notes__review-required-link {
  color: $nearly-black;
}


.notes__rejected-text {
  text-decoration: line-through;
  text-decoration-color: $mercury-grey;
}


.notes__rejected-image {
  border: solid 2px $silver-grey;
  height: 100px;
  line-height: 100px;
  padding: 10px;
  width: 150px;

  div {
    background: $aluminium-grey;
    height: 100%;
    text-align: center;

    i {
      color: $white;
      font-size: 45px;
    }
  }
}

.create-message {
  line-height: 1em;
  padding: .5em;

  img {
    background: $white;
    border: solid 1px $aluminium-grey;
    max-height: 316px;
    max-width: 316px;
    padding: .5em;

    @media (max-width: 600px) {
      margin-bottom: .5em;
    }
  }

  textarea::placeholder {
    color: $malachite-grey;
    font-style: normal;
  }

  /*
  textarea::-webkit-scrollbar {
    display: none;
  }
  */

  textarea {
    border-width: 1px;
    border-style: solid;
    box-shadow: none;
    color: $nearly-black;
    overflow-y: hidden;
    resize: none;
    transition: all 0.5s;

    &.expand {
      border-width: 2px;
      border-style: solid;
      max-height: 6em !important;
      min-height: 6em !important;
      overflow-y: auto;
    }
  }

}


.create-message__notes {

  margin: .25em 0 .5em;

  > div {
    margin-right: .75em;
  }

  i {
    font-size: 1.5em;
  }

  span {
    vertical-align: 15%;
  }
}

.create-message__file-selection {
  height: 1em;
  margin: .5em 0;
}

.create-message__selected-document {
  padding: .5em;

  @media (max-width: 600px) {
    margin-bottom: .5em;
  }
}

.create-message__task-duration {

  div {
    color: $titanium-grey;
    margin-right: .5em;
    max-width: 4em;

    @media (max-width: 600px) {
      margin-bottom: .5em;
    }

  }

  input {
    border-style: solid;
    border-width: 1px;
    border-color: $desaturated-cyan;
    border-radius: 0;
    box-shadow: none;
    height: 2em;
    margin-bottom: .5em;
  }
}

.note-action {
  height: 2em;
}

.note-action__button {
  border: 0;
  border-radius: .5em;
  color: $white;
  height: 2em;
  margin: 0 .2em;
  padding: 0 1em;
}

.note-action__button-cancel {
  background: $malachite-grey !important;
  color: $white !important;
  margin-left: .5em;
}

.audio-dialog {
  background: $transparent-blue;
  margin: .25em;
  padding: .25em;

  .audio-player {
    background: $white;
    padding: .5em;
  }
}

.audio-container {

  md-slider .md-track.md-track-fill {
    display: block;
  }

  md-slider .md-thumb {
    display: none;
  }

  .slider {
    margin: 0 .5em;
    width: 80%;
  }

  .microphone {
    background: $white;
    border-radius: 50%;
    color: $titanium-grey;
    height: 6em;
    margin: 0 auto;
    padding: 1em;
    text-align: center;
    width: 6em;

    &.recording {
      background: $blood-red;
      color: $white;
    }

  }

  .timer {
    color: $stone-grey;
    font-size: 3em;
    margin: .75em 0;
    text-align: center;
  }


  .audio-test {
    margin: .5em 0 0;
  }

}

.notes-filters-horizontal {
  padding: .5em 0 .25em 0;
}

.notes-filters-horizontal__initial-icon {
  margin-left: .75em;
  max-width: .75em !important;
  padding-bottom: .25em;
}

.notes-filters-horizontal__filter {
  color: $titanium-grey;
  cursor: pointer;
  margin-left: .75em;
  padding-bottom: .25em;

  .icon2-unapproved {
    font-size: .8em;
    margin-left: -.1em;
    margin-right: .1em;
  }

}

.notes-filters-horizontal__filter--selected {
  border-width: 0 0 3px 0;
  border-style: solid;
}

.notes-filters-vertical {
  padding: .5em 0 .25em 0;
}

.notes-filters-vertical--selected {
  border-width: 0 2px 0 0;
  border-style: solid;

  @media (max-width: 600px) {
    border: 0;
  }
}

.notes-filters-vertical__title,
.notes-filters-vertical__reset {
  padding: .25em .5em;
}


.notes-filters-vertical__filter {
  border-bottom: solid 2px $light-grey;
  border-top: solid 2px $light-grey;
  cursor: pointer;
  padding: .25em .5em;
}


.notes-filters-vertical__filter--selected {
  background: $white;
  border-bottom: solid 2px;
  border-top: solid 2px;
  width: calc(100% + 2px);

  @media (max-width: 600px) {
    width: 100%;
  }
}

.filters-sidenav {
  background: $light-grey;
  max-width: 10em;
  min-width: 10em;
}

.countersign-note {

  padding: 1em .5em;

  img {
    max-height: 30vh;
  }

  textarea {
    border: solid 1px $water-grey;
    box-shadow: none;
    color: $grey-body;
    margin-top: .5em;
    max-height: 2.2em;
    min-height: 2.2em;
    resize: none;
    transition: all .5s;

    &.expand {
      max-height: 4em;
      min-height: 4em;
    }
  }
}

.countersign-note__note {
  background: $pale-cyan;
  padding: 1.5em .5em;
}

.countersign-note__action-button {
  font-size: 1em;
  padding: .5em;
  width: 10em;
}

.countersign-note__action-button--reject {
  color: $glossy-black !important;
  margin-left: 0;
  margin-right: 0;
}

.countersign-note__action-button--countersign {
  color: $teal-velvet !important;
  margin-left: 0;
  margin-right: 0;
}

.countersign-note__action-button--disabled {
  color: $malachite-grey !important;
  pointer-events: none;
}

.page-numbers {
  background: $white;
  color: $grey-body;
  padding: 1em .5em;

  div div {
    cursor: pointer;
    height: 2em;
    line-height: 2em;
    text-align: center;

    &.page-number {
      max-width: 2em;
      min-width: 2em;
    }

    &.selected {
      background: $brilliant-sky;
      border-radius: 50%;
      color: $white;
    }
  }
}


.page-numbers__prev {
  margin-right: .3em;
}

.page-numbers__next {
  margin-left: .3em;
}

.page-numbers__page-dropdown {
  height: 2em;
  margin: 0 0 0 .5em;
  padding: 0;
  vertical-align: initial;

  md-select {
    height: 2em;
  }
}

  .notes-scrollbar::-webkit-scrollbar-thumb{
    background-color: $teal-velvet;
  }

  .notes-scrollbar::-webkit-scrollbar {
    width: 10px;
  }

.message-origin {
  display: block;
  position: relative;

  span.chip-wrapper{
    display: inline-block;
    margin: 0.5rem 0;
    padding: 0.3rem 0.5rem;
    border-radius: 0.3rem;
    background-color: $cream;
    color: $external;
    width: auto;

      i { //fa-icon
        margin-right: 0.3rem;
      }
    }
}