@import '../modules/history';

.value-toggle {
  margin: 1em auto;
  width: 12em;

  button {
    margin: 0;
    padding: .5em;
    width: 100%;
  }

  span {
    vertical-align: super;
  }
}

.history-container {
  background: $white;
  padding: .25em;
}

.repeating-history {
  background-image: repeating-linear-gradient(to right, $white, $white 16.67%, $odd 16.67%, $odd 33.33%);
  color: $grey-body;

  .card-divider {
    background: $light-grey;
    height: .5em;
    margin: .5em -.6em 0;
  }

  .columns {
    min-height: .1em;
    padding-left: 0;
    padding-right: 0;
  }

  .brilliant-sky {
    color: $brilliant-sky;
  }

  .orange {
    color: $orange;
  }

  .card-section {
    color: $teal-velvet;
  }

  .left-padding {
    padding-left: .5em;
  }

  .date {
    align-items: center;
    border: .15em solid $brilliant-sky;
    border-radius: 50%;
    display: flex;
    height: 2.5em;
    justify-content: center;
    margin: auto;
    width: 2.5em;

    &.unapproved {
      background: $theme-trainee-light;
      border-color: $theme-trainee-light;
      color: #ffffff;
    }
  }

  .history-card-preview {
    color: $jet-black;
    font-size: 1.3rem;
    font-weight: bold;
  }

  hr {
    border-color: $silver-grey;
    margin: 0 .3em;

    &.section-hr {
      border-color: $teal-velvet;
    }

    &.preview-hr {
      border-color: $jet-black;
    }
  }

  .time {
    font-size: .9em;
  }

  .desc-bar {
    background-image: linear-gradient(to right, $teal-velvet, $brilliant-sky);
    color: $white;
    padding: .3em .5em;
  }

  .radio-button-box {
    background-color: $white;
  }

  .details {
    border: .15em solid $teal-velvet;
    padding: 0 .5em;
  }

  .increased-size {
    font-size: 1.4em;
  }

}

.jet-black {
  color: $jet-black;
}

.ts-dialog {

  color: $grey-body;
  max-width: 38.4em;
  padding: 1em;

  .section {
    margin-bottom: 1em;
  }

  input.date {
    font-size: 1.3em;
    padding: .2em;
  }

  .heading {
    color: $brilliant-sky;
    font-size: 1.3em;
    padding-left: .73em;
    padding-right: .5em;
  }

  .close-dialog {
    color: $grey-body;
    cursor: pointer;
    font-size: 1.3em;
  }

  button {
    background: $theme;
    border: 0;
    border-radius: 0;
    color: #ffffff;
    font-size: 1.2em;
    margin-bottom: 0;
    padding: .2em 1.5em;

    &.date-selector {
      background: $mild-white;
      border-radius: 1em;
      color: $jet-black;
      font-size: 1em;
      margin-bottom: 1.5em;
      width: 9em;

      &.selected {
        background: $theme;
        color: #ffffff;
      }
    }

    &.cancel {
      background: $grey;
      color: $grey-body;
    }
  }

  textarea.reason {
    border: 1px solid $silver-grey;
    border-radius: 0;
    box-shadow: none;
  }

  .message-warning {
    padding: .5em;
  }

  md-slider .md-thumb::after {
    background-color: $brilliant-sky;
    border-color: $brilliant-sky;
  }

  md-slider .md-track.md-track-fill {
    background-color: $brilliant-sky;
  }

}

div.readmit-separator {
  color: $bright-orange;

  svg {
    height: 64px;
    position: absolute;
    right: -2px;
    top: 18px;
    width: 4px;
  }

  i {
    position: absolute;
    right: -6px;

    &.readmission {
      font-size: 1.25em;
      top: -10px;
    }
  }
}
