@import '../settings';

.task-avatar-border {
  border-color: $teal-velvet;
  background-color: $white !important;
  border-style: solid;
  border-width: 1px;
}

.task-avatar-normal {
  color: $teal-velvet !important;
}

.task-list md-divider {
  margin-top: 10px;
  margin-bottom: 0;
}

.task-status{

    md-select-value {
      font-size: 14px;
    }

    md-option{
      font-size: 14px;
    }
}

md-dialog {
  max-height : 650px !important;
}

.text-ellipsis--4{
  text-overflow:ellipsis;
  overflow:hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.text-ellipsis--3{
  text-overflow:ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
}


.notes__task-container {

  md-select {
    border-style: solid;
    border-width: 1px;
    margin-top: 10px;
    margin-bottom: 10px;

  }

  table{
    margin-bottom: 0.5em;
  }

  md-autocomplete-wrap{
    box-shadow: none;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(166, 211, 217);
    height: 30px
  }

  md-autocomplete {
    height: 30px;
  }

  #userSelectInput{
    height:30px;
    line-height: 30px;
  }
}
