@import '../settings';

.patient.data {
  @include overflow;
  background-color: $white;
  padding-bottom: 0;
  padding-top: 0;

  .row {
    height: 10em;
    margin-bottom: .2em;
  }

  .fill {
    box-sizing: border-box;
    left: -2em;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: calc(100% + 4em);
  }

  .third {
    display: inline-block;
    //padding: .1em;
    vertical-align: top;
    width: 33.333%;
  }

  .item {
    //@include panel;
    background: $white;
    min-height: 9em;
    padding: 1em 0 0;
    text-align: center;
  }
}
