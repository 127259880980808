@import '../settings';

$platinum-grey: #cecece;
$very-light-grey: #dfdfdf;
$yellow: #ebc500;

.patient-dashboard {

  background: $light-grey;

  h5, h6 {
    font-size: .7em;
    padding-bottom: .3em;
  }

  .fa-stack {
    height: 3em;
    line-height: 3em;
    width: 3em;
  }


  hr {
    margin: .5em 0 0;
  }

}


.patient-dashboard__forms {
  margin-top: 1em;
  text-align: center;
}

.patient-dashboard__icon {
}

.patient-dashboard__alert {
  font-size: 1.2em;
  left: 1.5em !important;
  z-index: 1;
}

.patient-dashboard__alert--warn {
  color: $kumquat-orange;
}

.patient-dashboard__alert--overdue {
  color: $postbox-red;
}

.patient-dashboard__countersign {
  font-size: 1.2em;
  left: 1.5em !important;
  top: 1.425em;
  z-index: 1;
}

.patient-dashboard__panel {
  background: $white;
  border-bottom: 2px solid $platinum-grey;
  border-left: 1px solid $platinum-grey;
  border-right: 2px solid $platinum-grey;
  color: $grey-body;
  line-height: 1.2em;
  margin-top: 1em !important;
}


.patient-dashboard__concern {
  padding: .35em;
}

.patient-dashboard__panel-title {
  border-bottom: 1px solid $very-light-grey;
  color: $white;
  line-height: 1.5em;
  padding: 0 .5em;
}

.patient-dashboard__panel-title--allergy {
  background: $danger;
  color: $jet-black;
}


.patient-dashboard__allergy {
  padding: .5em .5em 0;
}

.patient-dashboard__allergy__title {
  color: $jet-black;
  padding: 0 0 .5em;
}


.patient-dashboard__allergy__symptom {
  color: $grey-body;
  font-style: italic;
  padding: 0 0 .5em;
}

.patient-dashboard__obs-time {
  font-size: .8em;
}
