@import '../settings';
@import '../modules/panel';

.floating.panel {
  @include floating;
}

.panel {
  @include panel;

  &.blank {
    @include panel($white, $dark-grey);
  }

  &.success {
    @include panel($success);
  }

  &.danger {
    @include panel($danger);
  }

  &.warning {
    @include panel($warning);
  }

  &.external {
    @include panel($external);
    margin-bottom: 1em;
  }
}

.newpanel {

  @include panel;
  background: $light-grey;
  box-shadow: none;
  padding: 0;

  &.blank {
    background: $white;
  }

  &.padded {
    padding: .25em;
  }

}

.floating.newpanel {
  @include overflow;
  margin-bottom: .5em;
  padding: 0 !important;
}

.form-item {
  margin-top: .5em;

  a {
    color: $dark-grey;
  }
}
