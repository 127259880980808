@import '../settings';

/// @name Text Input
/// @author Dan Prince
/// A single line text input element.
@mixin text-input {
  @include outline;
  font-size: 1em;
  margin-bottom: .5em;
  outline: none;
  padding: .5em;
  width: 100%;

  &[external='true'] {
    background: lighten($external, 30%);
    border: solid 1px $external;
  }

  &.ng-invalid {
    background: lighten($danger, 33%);
    border: solid 2px $danger;
    color: $danger;
  }
}

