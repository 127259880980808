@import '../settings';
/**
*
*/
.md-table {
  min-width: 100%;
  border-collapse: collapse;
}

.md-table tbody tr:hover, .md-table tbody tr:focus {
  cursor:pointer;
  background-color:rgba(0,0,0,0.03);
}

.md-table-headers-row {
  vertical-align: middle;
}

.md-table-header {
  border-bottom: 1px solid rgb(230,230,230);
  color: rgb(130,130,130);
  text-align: left;
  font-size: 0.75em;
  font-weight: 700;
  padding: 0px 0px 0px 0px;
}

.md-table-header a {
  margin-left: 15px;
  text-decoration: none;
  color: inherit;
}

.md-table-td-check {
  width: 40px;
}

.md-table-td-more {
  width: 40px;
}

.md-button.md-default-theme.md-action {
  border-radius: 50%;
  z-index: 20;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
  -webkit-transition-property: -webkit-transform, box-shadow;
  transition-property: transform, box-shadow;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.md-table-header .unsorted {
  margin-right: 24px;
}

.md-table-caret {
  display: inline-block;
  vertical-align: middle;
}

.md-table-content {
  font-size: 0.9em;
  padding: 15px 15px 15px 15px;
}

.md-table-footer {
  margin: 0px 20px 0px 20px;
  height:40px;
}

.md-table-count-info {
  line-height:40px;
  font-size:.75em;
}

.md-table-footer-item {
  width: 40px;
  height: 40px;
  vertical-align:middle;
}

.md-table-page-link {
  text-decoration: none;
}

.md-table-active-page {
  font-weight: 700;
}

