@import '../modules/patient-preview';
@import '../modules/panel';

.patient-list {
  margin-top: .5em;
}

.patient-summary {
  background: $white;
  box-sizing: content-box;
  margin-bottom: .5em;
}

.patient-summary--dash {
  margin-bottom: 0;
  padding: .25em;
}

.patient.preview {
  @include patient-preview;

  .columns.columns {
    padding-left: 0;
    padding-right: 0;
  }

  &.grayout {
    a {
      color: $silver-grey !important;
    }
  }
}

.mini-preview {
  position: fixed;
  top: 4.8rem;
  width: 100%;
  z-index: 30;

  .info {
    background-color: #ffffff;
    margin-left: auto;
    margin-right: auto;
    max-width: 50em;
    padding: 0.3em;
  }
}
