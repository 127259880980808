@import '../settings';

/// @name Button
/// A button component.

@mixin button($background: $white, $border: $theme) {
  @include outline($border);

  background: $background;
  border-radius: .25em;
  color: $border;
  margin: 0 0 1.25rem;
  text-transform: uppercase;

  &:hover {
    @if $background == $white {
      background: lighten($border, 35%);
      color: $border;
    } @else {
      background: lighten($background, 35%);
      border-color: $background;
      color: $background;
    }
  }

  &:focus {
    background: $background;
    border-color: $border;
    color: $border;
    outline: none;
  }

  &[disabled] {
    background: $light-grey !important;
    border: solid 1px $grey !important;
    color: $grey !important;
  }
}
