@import '../modules/navbar';

.global {
  @include navbar;

  .left > a {
    display: inline-block;
  }
}

.mini-user {
  float: left;
  font-size: 9pt;
  line-height: 10pt;
  padding-right: 1em;
  padding-top: 0.5em;
}

.signoff-nav {
  background: linear-gradient(to right, $teal-velvet, $brilliant-sky);
  color: #ffffff;
}
