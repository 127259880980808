@import 'bourbon';
@import '../settings';


html {
  -ms-text-size-adjust: 100%;
  background: $light-grey;
}

body {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  background: $light-grey;
  padding-top: 4.8rem;
}

main {
  margin-left: auto;
  margin-right: auto;
  max-width: 50em;
  min-height: 80%;
}

img {
  max-width: 100%;
}

p {
  margin-bottom: .15em;
}
