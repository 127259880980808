@import '../settings';

/// A visually raised container for other elements.
@mixin panel($color: $theme, $text-color: $white) {
  @include shadowed;
  background: $color;
  border-color: $color;
  color: $text-color;
  overflow: visible;
  padding: .5em;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $text-color;
  }

  ul {
    margin-bottom: 0.5em;
  }
}

/// A floating variant on the regular panel style.
@mixin floating {
  @include overflow;
  margin-bottom: .5em;
}
