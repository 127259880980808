@import '../settings';
@import '../modules/button';

button,
.button {
  @include button;
}

button.success,
.button.success {
  @include button($success, $white);
}

button.warning,
.button.warning {
  @include button($warning, $white);
}

button.danger,
.button.danger {
  @include button($danger, $white);
}
