@font-face {
  font-family: 'icomoon2';
  src:  url('fonts/icomoon2.eot?2ekj4c');
  src:  url('fonts/icomoon2.eot?2ekj4c#iefix') format('embedded-opentype'),
    url('fonts/icomoon2.ttf?2ekj4c') format('truetype'),
    url('fonts/icomoon2.woff?2ekj4c') format('woff'),
    url('fonts/icomoon2.svg?2ekj4c#icomoon2') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon2-"], [class*=" icon2-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon2' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon2-Arrow-Up-WhiteOnPurple-Mobile .path1:before {
  content: "\e911";
  color: rgb(238, 231, 244);
}
.icon2-Arrow-Up-WhiteOnPurple-Mobile .path2:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(209, 180, 232);
}
.icon2-Arrow-Up-WhiteOnPurple-Mobile .path3:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon2-Expansion-Arrow-Up-WhiteOnPurple .path1:before {
  content: "\e902";
  color: rgb(238, 231, 244);
}
.icon2-Expansion-Arrow-Up-WhiteOnPurple .path2:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(209, 180, 232);
}
.icon2-Expansion-Arrow-Up-WhiteOnPurple .path3:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon2-Expansion-Arrow-Up-WhiteOnPurple .path4:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(223, 223, 223);
}
.icon2-Expansion-Arrow-Up-PurpleOnGrey .path1:before {
  content: "\e906";
  color: rgb(241, 242, 242);
}
.icon2-Expansion-Arrow-Up-PurpleOnGrey .path2:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(205, 205, 205);
}
.icon2-Expansion-Arrow-Up-PurpleOnGrey .path3:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(238, 231, 244);
}
.icon2-Expansion-Arrow-Up-PurpleOnGrey .path4:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(194, 177, 207);
  opacity: 0.9412;
}
.icon2-Expansion-Arrow-Down .path1:before {
  content: "\e90e";
  color: rgb(205, 205, 205);
}
.icon2-Expansion-Arrow-Down .path2:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon2-Expansion-Arrow-Down .path3:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(221, 221, 221);
  opacity: 0.9412;
}
.icon2-unapproved:before {
  content: "\e901";
}
.icon2-countersign:before {
  content: "\e900";
}
.icon2-safeguarding-active-trainee:before {
  content: "\e90a";
  color: #b289d4;
}
.icon2-allergies-active-trainee .path1:before {
  content: "\e90b";
  color: rgb(178, 137, 212);
}
.icon2-allergies-active-trainee .path2:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(235, 197, 0);
}
.icon2-allergies-active-trainee .path3:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
